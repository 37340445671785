<template>
    <div class="hotScripts-container">
        <div class="hotScripts-classify" ref="classifyBox" :style="classifyHeight">
            <span class="classify-left">分类:</span>
            <div class="classify-right">
                <span class="c-item" :class="{current: classifyCurrent === 0}" @click="toggleClassify(0)">全部</span>
                <span class="c-item"
                      :class="{current: classifyCurrent === classifyItem.id}" v-for="(classifyItem, classifyIndex) in classifyList"
                      :key="`classifyList_${classifyIndex}`" @click="toggleClassify(classifyItem.id)">
                    {{classifyItem.name}}
                </span>
            </div>
        </div>
<!--        <el-scrollbar class="hotScripts-content" :native="false">-->
        <div class="hotScripts-content" :style="`min-height: calc(100vh - ${this.classifyHeight}px - 142px);`">
            <div class="no-data" v-if="scriptsList.length === 0">
                <img src="@/assets/img/image/null-data.png" alt="">
            </div>
            <div class="h-item" v-for="(scriptsItem, scriptsIndex) in scriptsList" :key="`scriptsList_${scriptsIndex}`">
                <div @click="toScriptDetail(scriptsItem.id)" class="h-item-cover">
                    <img :src="scriptsItem.cover_url?scriptsItem.cover_url:require('../../../assets/img/image/moren_bg.jpg')">
                </div>
                <div class="h-item-content">
                    <p class="line">
                        <span @click="toScriptDetail(scriptsItem.id)" class="title text-overflow" :title="scriptsItem.name">{{scriptsItem.name}}</span>
<!--                        <i class="iconfont icon-hot">&#xe781;</i>-->
                    </p>
                    <div class="line line-two">
                        <div class="avatar">
                            <div class="avatar-cover">
                                <img :src="scriptsItem.create_heard_url ? scriptsItem.create_heard_url : require('../../../assets/img/image/user_img.png')" alt="">
                            </div>
                            <span class="name">{{scriptsItem.create_name}}</span>
                        </div>
                        <div class="btn">
                            <el-link class="blue" @click="toScriptDetail(scriptsItem.id)">查看详情</el-link>
<!--                            <el-link type="danger" @click="downScript(scriptsItem)">立即下载</el-link>-->
                        </div>
                    </div>
                </div>
            </div>
        </div>
<!--        </el-scrollbar>-->
        <el-pagination class="pages-center" background v-if="scriptsList.length > 0"
                       :current-page.sync="scriptsPages.currentPageNum"
                       :page-size="scriptsPages.eachPageNum"
                       :total="scriptsPages.total"
                       layout="prev, pager, next, jumper"
                       @current-change="scriptsCurrentChange">
        </el-pagination>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                classifyList: [],
                classifyCurrent: 0,
                scriptsList: [],
                scriptsPages: {
                    currentPageNum: 1,
                    eachPageNum: 12,
                    total: 0,
                },
                classifyHeight: '',
                contentHeight: ''
            }
        },
        mounted() {
            this.getClassifyList();
            this.getScriptsList();
        },
        methods: {
            getClassifyList() {
                this.$httpStudent.axiosGet(this.$api.getClassification,(res) => {
                    if (res.code === 200) {
                        this.classifyList = res.data.data
                        this.$nextTick(() => {
                            this.classifyHeight = this.$refs.classifyBox.offsetHeight
                        })
                    } else {
                        this.$message.error(res.msg)
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            toggleClassify(id) {
                this.classifyCurrent = id
                this.getScriptsList()
            },
            // 获取脚本列表
            getScriptsList() {
                let param = {
                    page: this.scriptsPages.currentPageNum,
                    pageSize: this.scriptsPages.eachPageNum,
                    paging: 1,
                    status: 1,
                }
                if (this.classifyCurrent) {
                    param.classification_id = this.classifyCurrent
                }
                this.$httpStudent.axiosGetBy(this.$api.getScript, param, res => {
                        if (res.code === 200) {
                            this.scriptsList = res.data.data
                            this.scriptsPages.total = res.data.total
                            this.$message({
                                type: "success",
                                message: res.message,
                                duration: 1000,
                            });
                        } else {
                            this.$message({
                                type: "error",
                                message: res.message,
                                duration: 1000
                            });
                        }
                    },
                    err => {
                        console.log(err);
                    });
            },
            scriptsCurrentChange(val) {
                this.scriptsPages.currentPageNum = val;
                this.getScriptsList();
            },
            toScriptDetail(id) {
                this.$router.push({
                    path: '/student/script/view/creation',
                    query: {id: id}
                })
            },
            // downScript(item) {
            //     window.location.href = item.script_file_down;
            // }
        }
    }
</script>

<style scoped lang="scss">
    ::v-deep p {
        margin: 0;
    }
    .hotScripts-container {
        position: relative;
        overflow: hidden;
        .hotScripts-classify {
            background: #fff;
            border-radius: 8px;
            display: flex;
            flex-wrap: wrap;
            padding: 20px 20px 10px;
            .classify-left {
                line-height: 30px;
            }
            .classify-right {
                width: 1%;
                flex: 1;
                .c-item {
                    cursor: pointer;
                    border-radius: 4px;
                    display: inline-block;
                    line-height: 30px;
                    padding: 0 10px;
                    margin: 0 0 10px 10px;
                    transition: all .3s;
                    &.current {
                        color: #2338E6;
                        background: #BEC3F1;
                    }
                    &:hover {
                        color: #2338E6;
                    }
                }
            }
        }
        .hotScripts-content {
            /*height: 1%;*/
            /*flex: 1;*/
            //margin-top: -20px;
            margin-right: -20px;
            display: flex;
            flex-wrap: wrap;
            /*min-height: calc(100vh - 212px);*/
            /*::v-deep .el-scrollbar__wrap {*/
            /*    overflow-x: hidden;*/
            /*    .el-scrollbar__view {*/
            /*        display: flex;*/
            /*        flex-wrap: wrap;*/
            /*    }*/
            /*}*/
            .h-item {
                width: calc(25% - 20px);
                margin: 20px 20px 0 0;
                border-radius: 8px;
                overflow: hidden;
                .h-item-cover {
                    width: 100%;
                    height: 0;
                    padding-bottom: 57.397959%;
                    position: relative;
                    overflow: hidden;
                    cursor: pointer;
                    background: #fff;
                    img {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        max-width: 100%;
                        max-height: 100%;
                    }
                }
                .h-item-content {
                    background: #fff;
                    padding-bottom: 16px;
                    .line {
                        padding: 16px 16px 0;
                        &.line-two {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                        }
                        .title {
                            color: #333;
                            width: 100%;
                            display: block;
                            cursor: pointer;
                            &:hover {
                                color: #1E33E3;
                            }
                        }
                        .icon-hot {
                            color: #FF0000;
                            margin-left: 10px;
                            font-size: 18px;
                            line-height: 1;
                        }
                        .avatar {
                            display: flex;
                            align-items: center;
                            .avatar-cover {
                                width: 24px;
                                height: 24px;
                                border-radius: 50%;
                                overflow: hidden;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                img {
                                    max-width: 100%;
                                    max-height: 100%;
                                }
                            }
                            .name {
                                margin-left: 10px;
                                font-size: 12px;
                            }
                        }
                        .btn {
                            .el-link {
                                margin-left: 10px;
                            }
                        }
                    }
                }
            }
        }
    }
    .el-link.blue {
        color: #1E33E3;
        &:hover {
            color: #3449fa;
        }
    }
    .no-data {
        width: 100%;
        margin-top: 100px;
        text-align: center;
    }
</style>
